<template>
  <div>
    <section class="publicfund ">
      <div class="publicfund_cont">
        <div class="title_">
          <router-link :to="{name:'GetMoney'}" class="iconfont arrleft"
            >&#xe665;</router-link
          >
          惠金宝
        </div>
        <div class="side_cont">
          <ProgressBar :sideData="side" />
        </div>
        <div class="solids_"></div>
        <div class="buttom">
          <div class="card">
            <div class="bankcard">{{ bancknum }}</div>
            <div class="card-text">
              <div>您即将从<span>惠金宝</span>提现</div>
              <div>
                <span>{{ numprice || "--" }}</span>
                <span>元</span>
              </div>
              <div>
                至银行卡:<span>{{ banckname }}(尾号{{ lastnum }})</span>
              </div>
            </div>
          </div>
          <div class="input-top">
            <div>请输入交易密码：</div>
            <a @click="forget">忘记密码？</a>
          </div>
          <input
            type="password"
            v-model="mypassworld"
            placeholder="请输入6位交易密码"
          />
          <!-- <div   class="errmsg">{{errmsg}}</div> -->
          <div class="bt">
            <a class="btn1" @click="sure">确定</a>
            <router-link :to="{name:'GetMoney'}" class="btn2"
              >返回上一步</router-link
            >
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import ProgressBar from "@/components/ProgressBar";
import { redeemQuick, redeem } from "@/api/getMoney";

export default {
  name: "GetMoney2",
  components: { ProgressBar },
  data() {
    return {
      mypassworld: null,
      side: [
        { title: "填写提现信息", type: 1 },
        { title: "输入交易密码", type: 2 },
        { title: "交易结果", type: 0 },
      ],
      errmsg: "",
      numprice: "",
      bancknum: "",
      lastnum: "",
      banckname: "",
      bankInfo:{}
    };
  },
  methods: {
    forget() {
      this.$router.push("/myFavors/accountManage/safetyCentre");
    },
    sure() {     
      if(/^\d{6}$/.test(this.mypassworld)){
        if (sessionStorage.getItem("border") == 1) {
          redeemQuick({
            share: sessionStorage.getItem("numprice"),
            tradePassword: this.mypassworld,
            channelSource: 1,
            tradeAcco:this.bankInfo.tradeAcco,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$router.push({name:"GetMoneyS3"});
              }
            })
            .catch((err) => {
              this.errmsg = err;
            });
        } else if (sessionStorage.getItem("border") == 2) {
          redeem({
            share: sessionStorage.getItem("numprice"),
            tradePassword: this.mypassworld,
            channelSource: 1,
            tradeAcco:this.bankInfo.tradeAcco,
          })
            .then((res) => {
              if (res.code == 200) {
                this.$router.push({name:"GetMoneyS3"});
              }
            })
            .catch((err) => {
              this.errmsg = err;
            });
        }
      }else{
        this.$message.error('请输入6位数字密码')
      }
    },
  },
  mounted() {
    this.numprice = sessionStorage.getItem("numprice");
    let banckdata = JSON.parse(localStorage.getItem("banckdata"));
    this.bankInfo=banckdata;
    // console.log(banckdata.banckNo)
    this.lastnum = banckdata.bankAccount.substr(-4, 4);
    this.banckname = banckdata.bankName;
    this.bancknum = banckdata.bankAccount
      .replace(/\s/g, "")
      .replace(/(.{4})/g, "$1 ");
  },
};
</script>
<style lang="less" scoped>
::-webkit-input-placeholder {
  color: #a5a5a5;
  font-size: 14px;
}
.profitac {
  color: #e45247 !important;
}
.profit {
  color: #01b834 !important;
}
.publicfund {
  padding-bottom: 40px;
  min-height: 600px;
  display: flex;
  .publicfund_cont {
    background: #fff;
    min-height: 1290px;
    width: 930px;
    .title_ {
      padding: 21px 40px 0px 43px;
      height: 60px;
      font-weight: 500;
      font-size: 20px;
      color: #1f1f1f;
      .arrleft {
        margin-right: 14px;
        color: #bdc0cb;
      }
    }
    .side_cont {
      height: 146px;
      padding: 0px 0px 0px 80px;
      display: flex;
      align-items: center;
    }
    .solids_ {
      height: 2px;
      background: linear-gradient(270deg, #eabf96 0%, #f7d9b7);
      box-shadow: 0px 4px 12px 0px #edf1f7;
    }

    .buttom {
      margin-top: 30px;
      margin-left: 40px;
      .card {
        display: flex;
        .bankcard {
          background-image: url("../../../static/img/my/bank card.png");
          width: 268px;
          height: 161px;
          border-radius: 14px;
          font-size: 18px;
          color: #ffffff;
          text-align: center;
          line-height: 161px;
        }
        .card-text {
          margin: 34px 0 0 58px;
          div {
            &:first-child {
              font-size: 16px;
              font-weight: 300;
              color: #1f1f1f;
              margin-bottom: 10px;
              span {
                color: #ce9b63;
              }
            }
            &:nth-child(2) {
              font-size: 26px;
              color: #e45247;
              margin-bottom: 10px;
              span {
                &:nth-child(2) {
                  font-size: 14px;
                  margin-left: 6px;
                }
              }
            }
            &:last-child {
              font-size: 16px;
              font-weight: 300;
              color: #1f1f1f;
              span {
                color: #ce9b63;
              }
            }
          }
        }
      }
      .input-top {
        display: flex;
        margin-top: 40px;
        div {
          &:first-child {
            font-size: 16px;
            font-weight: 400;
            color: #1f1f1f;
          }
        }
        a {
          font-size: 14px;
          font-weight: 400;
          color: #a5a5a5;
          margin-left: 322px;
        }
      }
      input {
        width: 520px;
        height: 48px;
        margin-top: 14px;
        border: 1px solid #e0e2e8;
        border-radius: 2px;
        padding-left: 24px;
      }
      .errmsg {
        height: 20px;
        color: red;
      }
      .bt {
        margin-top: 40px;
        display: flex;
        .btn1 {
          width: 240px;
          height: 48px;
          background: linear-gradient(101deg, #f7d9b7 0%, #eabf96 100%);
          border-radius: 2px;
          color: #ce9b63;
          font-size: 16px;
          text-align: center;
          line-height: 48px;
        }
        .btn2 {
          color: #ce9b63;
          font-size: 16px;
          margin-left: 20px;
          line-height: 48px;
        }
      }
    }
  }
}
</style>
